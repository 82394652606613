
import {defineComponent} from 'vue';
import {IonIcon, IonLabel, IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs} from '@ionic/vue';
import {ellipse, square, triangle} from 'ionicons/icons';
import {useAuthStore} from "@/store";
import {emitter} from "@/bus";
import {useRouter} from "vue-router";

export default defineComponent({
  name: 'TabsPage',
  components: {IonTabs, IonPage, IonRouterOutlet},
  data() {
    return{
      badgeImage: '',
      router: useRouter(),
    }
  },
  setup(){
    return {
      ellipse,
      square,
      triangle,
    }
  },
});
