
import {defineComponent} from "vue";
import {IonProgressBar} from '@ionic/vue';

export default defineComponent({
  name: "UploadProgress",
  components: {IonProgressBar},
  props: {
    uploadProgress: Number
  }
});
