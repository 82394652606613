
import {defineComponent} from 'vue';
import {IonIcon, IonLabel, IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs} from '@ionic/vue';
import {ellipse, square, triangle} from 'ionicons/icons';

export default defineComponent({
  name: 'TabsExamplePage',
  components: {IonTabs, IonPage, IonRouterOutlet},
  setup(){
    return {
      ellipse,
      square,
      triangle,
    }
  }
});
