import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

import { emitter } from  './bus';
declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    emitter: typeof emitter;
  }
}

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import axios from 'axios'
import VueAxios from 'vue-axios'

/* state management */
import { createPinia } from "pinia";
import { useAuthStore } from "./store";
import {initializeAuthListener} from "@/store/firebase";

const app = createApp(App)
  .use(IonicVue)
  .use(VueAxios, axios)
  .use(createPinia());

// - get the auth store
const store = useAuthStore();
// - initialize auth listener to see if we have a user at startup
initializeAuthListener().then(() => {
  app.use(router);
  return router.isReady();
}).then(() => {
  app.mount("#app");
});
