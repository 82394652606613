import firebase from 'firebase';
import {useAuthStore} from "@/store/user";
import router from "@/router";

const configOptions = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};
firebase.initializeApp(configOptions);

export const fbSignIn = async (email: string, password: string) => {
    const response = await firebase.auth().signInWithEmailAndPassword(email, password);
    return response;
};

export const fbLogout = async () => {
    await firebase.auth().signOut();
    const store = useAuthStore();
    store.user = false;
    store.idtoken = '';
    return true;
};

export const initializeAuthListener = () => {
    return new Promise((resolve) => {
        firebase.auth().onAuthStateChanged(function(user: any) {
            if(user?.email.includes('@leagues.football')){
                const store = useAuthStore();
                store.user = true;
                user.getIdToken(true).then(function(data: string) {
                    store.idtoken = data;
                })
                if(router.currentRoute.value.path === '/login'){
                    router.push('/')
                }
            }
            resolve(true);
        })
    });
};
